import { Action, createAction, createReducer, on, props } from '@ngrx/store';
import { IUserStatus } from '@rdlabo/odss-mobile-api/src/shared/auth/auth.api';

export const defaultProfile = (): IUserStatus => ({
  profile: {
    aeId: null,
    permission: null,
    companyId: null,
    name: null,
    companyName: null,
    position: null,
    portableTelephone: null,
    mailAddress: null,
    lineId: null,
    note: null,
  },
});

export const actionStatus = createAction('[STORE]', props<IUserStatus>());

const ReducerFunction = createReducer(
  defaultProfile(),
  on(actionStatus, (state, reload) => {
    return reload;
  }),
);

export function reducer(state: IUserStatus, action: Action) {
  return ReducerFunction(state, action);
}
