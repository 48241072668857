import { inject, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { IUserStatus } from '@rdlabo/odss-mobile-api/src/shared/auth/auth.api';
import { IAe } from '@rdlabo/odss-mobile-api/src/endpoint/resources/ae/ae.interface';
import { HelperService } from '../../../../shared/src/lib/services/helper.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly http = inject(HttpClient);
  private readonly loadingCtrl = inject(LoadingController);
  private readonly helper = inject(HelperService);

  constructor() {}

  getStatus(): Promise<IUserStatus> {
    return firstValueFrom(this.http.get<IUserStatus>(environment.api() + 'auth/status'));
  }

  updateProfile(profile: IAe): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(environment.api() + 'auth/profile', profile).pipe(
        tap(async () => {
          await this.helper.presentToast({
            message: '保存しました',
          });
        }),
      ),
    );
  }

  async getNotifications(): Promise<void> {
    return firstValueFrom(this.http.get<void>(environment.api() + 'auth/notifications'));
  }

  async updateNotifications(notifications): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(environment.api() + 'auth/notifications', notifications).pipe(
        tap(async () => {
          await this.helper.presentToast({
            message: '保存しました',
          });
        }),
      ),
    );
  }
}
